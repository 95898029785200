* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.latestnews {
  font-size: 2em;
  /* Adjust the size as needed */
  font-weight: bold;
  background: linear-gradient(
    90deg,
    red,
    orange,
    yellow,
    green,
    blue,
    indigo,
    violet
  );
  background-size: 400%;
  /* Increase background size to enable smooth animation */
  -webkit-background-clip: text;
  color: transparent;
  overflow: hidden;
  /* Ensure the text doesn't overflow */
  white-space: nowrap;
  /* Prevent the text from wrapping */
  border-right: 2px solid transparent;
  /* Add a cursor effect */
  animation: typing 4s steps(30) 1s infinite normal both,
    /* Typing effect */ colorChange 7s infinite linear;
  /* Gradient animation */
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes colorChange {
  0% {
    background-position: 0% 50%;
  }

  /* Start from the left */
  100% {
    background-position: 100% 50%;
  }

  /* End at the right */
}

.lastestnews-event {
  height: 38px;
  color: black;
  width: 100%;
  display: flex;
  justify-content: Start;
  align-items: start;
  margin-top: 68px;
  margin-left: 41px;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 1px solid black;
}

.lastestnews-event h3 {
  font-size: 24px !important;
}

.lastestnews-updates {
  height: 35px;
  color: black;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: start;
  margin-top: 86px;
  margin-left: 0px;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 1px solid black;
}

/* *{
    outline: 2px solid green;
} */

.lastestnews-updates h3 {
  font-size: 24px;
}

.p-5 {
  padding: 1rem !important;
}

.img-container {
  position: relative;
  width: 81% !important;
  height: 1009px !important;
  overflow: hidden;
  margin-top: 52px !important;
}

.image-container {
  position: relative;
  width: 100%;
  height: 250px;
  overflow: hidden;
}

.my-card {
  height: 490px !important;
}

.image {
  width: 100%;
  height: 250px;
  transition: filter 0.3s ease;
}

.image1 {
  width: 100%;
  height: 535px;
  transition: filter 0.3s ease;
}

.image-container:hover .image {
  filter: blur(1px);
}

.img-container:hover .image1 {
  filter: blur(1px);
}

.overlay1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.img-container:hover .overlay1 {
  opacity: 1;
}

.image-container:hover .overlay1 {
  opacity: 1;
}

.plus-sign {
  padding: 18px;
  color: white;
  opacity: 0;
  transform: scale(0);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.img-container:hover .plus-sign {
  opacity: 1;
  transform: scale(1);
}

.image-container:hover .plus-sign {
  opacity: 1;
  transform: scale(1);
}

.campaign-img img {
  height: 236px;
  width: 100% !important;
  margin-left: -9px;
  border-radius: 7px;
  object-fit: cover;
  object-position: center;
}

.card-imagition {
  height: 480px;
  /* border: 2px solid green; */
}

.card-imagition1 {
  height: 380px;
}

/* *{
    outline:2px solid green
} */

/* Tablet devices (portrait and landscape) */
@media (max-width: 480px) {
  .latestnews {
    font-size: 1.5em;
  }

  .lastestnews-event {
    height: 51px;
    color: black;
    width: 100%;
    margin-left: 2px;
    border-bottom: 1px solid black;
  }

  .img-container {
    height: 351px;
    margin-top: -21px;
  }

  .image-container {
    height: 351px;
    width: 300px;
    left: -17px;
    margin-top: -21px;
  }

  .image {
    height: 300px;
  }

  .image1 {
    height: 300px;
  }

  #section3 {
    margin-top: -101px;
  }

  .lastestnews-updates {
    margin-top: -2px;
    margin-left: -3px;
  }

  .campaign-img img {
    margin-left: -2px;
    object-fit: cover !important;
    width: 370px !important;
  }

  /* Small desktops and larger tablets */
  @media (max-width: 768px) {
    .latestnews {
      font-size: 1.5em;
      /* Adjust font size for larger tablets and small desktops */
    }

    .image-container {
      height: 370px;
      margin-top: -43px;
      width: 100%;
      left: 45px;
    }

    .image {
      height: 325px;
    }

    .image1 {
      height: 350px;
    }
  }
}

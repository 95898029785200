.text-muted {
  --bs-text-opacity: 1;
  /* color: #6c757d!important; */
  color: white !important;
}
.input-group-text1 {
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 5px 0px 0px 5px;
}
.login-container {
  height: 100vh;
  background: linear-gradient(to right, #d31919 50%, #ffff 50%);
}

.login-card {
  max-width: 400px;
  border-radius: 10px;
  overflow: hidden;
  border-radius: 10px;
}

.login-card .card-title {
  font-size: 24px;
  color: #007bff;
  font-weight: bold;
  text-transform: uppercase;
}

.login-card .text-muted {
  color: #6c757d !important;
}

.password-container {
  position: relative;
}

.password-container .toggle-password {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.fade-appear,
.fade-enter {
  opacity: 0;
  transform: scale(0.9);
}

.fade-appear-active,
.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 500ms, transform 500ms;
}

.dashboard-container {
  padding: 20px;
  background-color: #f0f2f5;
}

h2 {
  color: #007bff;
  font-weight: bold;
}

.dashboard-card {
  background-color: white;
  border: none;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.dashboard-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.dashboard-card-title {
  color: #333;
  font-weight: 600;
  font-size: 18px;
}

.dashboard-card-text {
  font-size: 24px;
  font-weight: bold;
  color: #007bff;
}

.tablecolor {
  --bs-table-bg: rgb(184, 15, 15);
  --bs-table-color: white;
}


@media (max-width: 767px) {
  .dashboard-card-text {
    font-size: 20px;
  }
}

.dashboard-box {
  width: 350px;
  background-color: #d31919;
  color: #ffffff; 
  box-shadow: inset 10px 10px 20px rgba(0, 0, 0, 0.4), inset -10px -10px 20px rgba(255, 255, 255, 0.2);
  padding: 30px;
  border-radius: 30px;
  height: 250px;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.dashboard-box:hover {
  transform: translateY(-10px);
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.5), 0 0 40px rgba(255, 255, 255, 0.3);
}

.dashboard-box::before,
.dashboard-box::after {
  content: '';
  position: absolute;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.4);
  pointer-events: none;
  transition: opacity 0.3s ease;
  opacity: 0;
}

.dashboard-box::before {
  top: 50%;
  left: 50%;
  width: 150px;
  height: 150px;
  transform: translate(-50%, -50%);
  background: radial-gradient(circle, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 70%);
}

.dashboard-box::after {
  top: 50%;
  left: 50%;
  width: 250px;
  height: 250px;
  transform: translate(-50%, -50%);
  background: radial-gradient(circle, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 70%);
}

.dashboard-box:hover::before {
  opacity: 1;
  animation: spark-1 1s infinite;
}

.dashboard-box:hover::after {
  opacity: 1;
  animation: spark-2 1s infinite;
}

@keyframes spark-1 {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 1;
  }
  50% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.5;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0;
  }
}

@keyframes spark-2 {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 1;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.2);
    opacity: 0.7;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.8);
    opacity: 0;
  }
} 



.dashboard-heading h2 {
  margin: 0; /* Remove default margin */
  font-size: 2.5rem; /* Larger font size for prominence */
  color: #d31919;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  font-weight: bold;
  
}

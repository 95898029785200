*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.footer {
    background-color: #313131;
    color: #fff;
    padding: 40px 20px;
  }
  
  .footer-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .footer-column {
    flex: 1;
    min-width: 250px;
    margin: 10px 20px;
  }
  
  .footer-column h3 {
    margin-bottom: 20px;
  }
  
  .footer-column p, .footer-column ul, .footer-column li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .footer-column ul {
    padding: 0;
  }
  
  .footer-column ul li a {
    color: #fff;
    text-decoration: none;
    display: block;
    margin-bottom: 10px;
    transition: color 0.3s;
  }
  
  .footer-column ul li a:hover {
    color: #ff6347; /* Change to your preferred hover color */
  }

  .footer-email{
     text-decoration: none;
     color: white !important;
  }
  
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-column {
      text-align: center;
      margin: 10px 0;
    }
  }
  
  .footer-joinus{
    height: 50px;
    width: 125px;
    background-color: rgb(236, 7, 7);
    color: white;
    font-size: 20px;
    /* border: none; */
    border-radius: 5px;
    font-weight: 600;
    border: 3px solid white;
  }
  
  .footer-donate{
    height: 50px;
    width: 125px;
    background-color: rgb(248, 7, 7);
    color: white;
    font-size: 20px;
    /* border: none; */
    border-radius: 5px;
    margin-top: 3px;
    font-weight: 600;
    border: 3px solid white;
  }

  .tandc{
    color: unset;
    text-decoration: none;
  }
  
  .privacy{
    color: unset;
    text-decoration: none;
  }

  .tandc:hover{
    color: red;
    text-decoration: none;
  }

  .privacy:hover{
    color: red;
    text-decoration: none;
  }
.contact-us{
    /* margin-top: 30px; */
    margin-bottom: 15px;
}  
  .company-address h3 {
    margin-bottom: 15px;
    margin-top: 25px;
  }
  
  .form-group label{
    margin-top: 18px;
    font-weight: bold;
    font-size: 26px;
  }
.butt{
  height: 45px;
  width: 115px;
  background-color: red;
  color: white;
  font-size: 18px;
  font-weight: bold;
  /* border: none; */
  border-radius: 5px;
  /* margin-left: 97px; */
  border: 3px solid white;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-color: #af0c0c;
  --bs-btn-hover-border-color: #ffffff;
}

.message-us{
  height: auto;
  width: 100%;
  background: #d90812;
  color: white;
  padding: 10px;
  margin-top: 25px;
  box-shadow: 0 0 10px 1px red;
}
  @media (max-width: 768px) {
    .map iframe {
      height: 200px;
    }
    .contact-us{
      font-size: 40px !important;
  }  
  }
  
  @media (max-width: 480px) {
    .map iframe {
      height: 150px;
    }
    .butt{
      margin-left: 0;
    }
    .form-group label {
      font-size: 26px;
  }
  
  
  }
  
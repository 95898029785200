/* Modal.css */
/* *{
  outline: 2px solid black;
} */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: scroll;
}

.modal-content {
  margin-top: 50px;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  /* width: 90%;
  max-width: 500px; */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  /* box-shadow: 0 0 16px 5px black; */
  /* height: 95%; */
}

.modal-header {
  margin-bottom: 5px;
  color: red;
  /* text-align: center; */
}
.modal-header > h4 {
  /* margin-left: 130px; */
  text-align: center;
  margin-top: -2px;
  border-bottom: 3px solid rgb(228, 15, 15);
}

.close-button {
  background: none;
  border: none;
  font-size: 25px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
}

.modal-body form {
  display: flex;
  flex-direction: column;
}

.modal-body label {
  color: black;
  font-weight: bold;
  font-size: 16px;
  position: relative;
  left: 3px;
}

.modal-body input,
.modal-body select,
.modal-body textarea {
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 15px;
  font-size: 13px;
}

.modal-body button {
  padding: 10px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 150px;
  margin: 0 auto;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .modal-content {
    width: 95%;
    max-width: 400px;
  }
  .modal-header > h4 {
    margin-left: 108px;
    margin-top: -10px;
  }
  .modal-body label {
    color: black;
    font-weight: bold;
    font-size: 14px;
  }
  .modal-body input,
  .modal-body select,
  .modal-body textarea {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 4px;
    font-size: 12px;
  }

  .modal-body button {
    padding: 10px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 150px;
    margin: 0 auto;
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .modal-content {
    width: 96%;
    max-width: 350px;
    padding: 11px;
  }

  .modal-body button {
    width: 100%;
  }
  .close-button {
    background: none;
    border: none;
    font-size: 25px;
    cursor: pointer;
    position: absolute;
    top: 2px;
    right: 15px;
  }
  .modal-body input,
  .modal-body select,
  .modal-body textarea {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 8px;
    font-size: 12px;
  }

  .modal-body label {
    color: black;
    font-weight: bold;
    font-size: 13px;
  }
  .modal-body button {
    padding: 6px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 125px;
    margin: 0 auto;
    margin-top: 5px;
  }

  .modal-header > h4 {
    margin-left: 10px;
    margin-top: -3px;
  }
}

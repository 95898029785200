.side-nav{
    background-color: #ba1d1d;
    color: #333333;
    font-size: large;
    overflow: hidden;
    overflow-y: scroll;
    height: 500px;
   
}
.side-nav.ul.li{
    list-style-type: none !important;
}
.li-remove{
    list-style-type: none;
    background-color:#ffff;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 5px;
    color: #ba1d1d;
}
.li-remove:hover{
   
    transition: all linear 0.38s;
}
.active{
    background-color: #ba1d1d;
    border: 3px solid white;
    color: #ffff;
}

.side-nav ul a{
    text-decoration: none;
    font-weight: 700;
}
/* .li-remove p:hover{
  color:#181133 !important
} */

.admin-logout {
    background-color: #ffffff !important; /* White background */
    color: #d31919 !important; /* Red text color */
    border: 1px solid #d31919 !important; /* Red border */
}

.admin-logout:hover,
.admin-logout:focus  {
    background-color: #ffffff !important; /* White background on hover */
    color: #d31919 !important; /* Red text on hover */
    border-color: #d31919 !important;
}

.admin-logout::after {
    color: #d31919 !important; /* Red color for the dropdown arrow */
}


.dropdown-menu {
    background-color: #ffffff; /* White dropdown background */
    border: 1px solid #d31919 !important; /* Red border for the menu */
}

.dropdown-item {
    color: #d31919 !important; /* Red text for dropdown items */
}

.dropdown-item:hover {
    background-color: #f8d7da; /* Light red on hover */
    color: #d31919 !important; /* Red text on hover */
}
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.gallery{
    height: 20vh;
    width: 100%;
    background-color: #820a0a;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
   
}
.galleryimg{
    height: 200px;
    width: 100%;
    border-radius: 5px;
}
.myBreadcrumb{
    position: absolute;
    bottom: 10px;
    left: 10px;
}
.myBreadcrumb {
    color: white; /* General color for breadcrumb */
  }
  .breadcrumb-item>a{
    color: white;
    text-decoration: none;
  }
  .myBreadcrumb .breadcrumb-item + .breadcrumb-item::before {
    color:white; /* Set the color you want for the separator */
  }
  
  
@media (max-width:480px) {
    .gallery{
        height: 15vh;
        width: 100%;
        background-color: black;
        color: #D90812;
        display: flex;
        justify-content: center;
        align-items: center;
       
    }
    .galleryimg{
        height: 285px;
        width: 100%;
        border-radius: 5px;
    }
    .myBreadcrumb{
        display: none;
    }
}
@media (max-width:768px) {
    .gallery{
        height: 15vh;
        width: 100%;
        background-color: #D90812;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
       
    }
    .galleryimg{
        height: 285px;
        width: 100%;
        border-radius: 5px;
    }
}
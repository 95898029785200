*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.details-news{
    height: 15vh;
    width: 100%;
    background-color: #891111;
    color: white;
   display: flex;
   justify-content: center;
   align-items: center;
}
.details-news-img{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 8px;
    padding-top: 25px;
    /* margin-top: 50px; */
    margin-bottom: 25px;
}
.details-news-img>img{
    height: 70vh;
    width: 80%;
    object-fit: cover;
}
.details-news-content{
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
    /* height: auto; */
    width: 80%;
}

.details-news-content p{
    margin-bottom: 5px;
    text-align:justify;
}


